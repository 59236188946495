import api from "../axios/axios";

export const getAllUsers = () => {
  return api.get("/admin/users");
};
export const getUserCount = (startDate: any, endDate: any) => {
  return api.get("/admin/users/count", {
    params: {
      startDate,
      endDate,
    },
  });
};
export const getAllSettings = () => {
  return api.get("/admin/settings");
};
export const updateUserStatus = (user: any) => {
  return api.put(`/admin/users/${user._id}/status`, {
    status: user.role,
  });
};
export const updateSettingValue = (setting: any) => {
  return api.put(`/admin/settings/${setting._id}`, {
    value: setting.value,
  });
};

export const impersonateUser = (user: any) => {
  return api.post(`/admin/impersonate-user`, {
    id: user._id,
  });
};
